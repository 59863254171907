import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="content has-text-centered">
          <p>&copy; {new Date().getFullYear()}, fruitymidnight.net</p>
        </div>
      </footer>
    )
  }
}

export default Footer